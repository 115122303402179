@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Baby Doll';
  src: url('../assets/fonts/baby-doll/baby-doll.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Black';
  src: url('../assets/fonts/montserrat-black/montserrat-black.woff')
    format('woff');
  font-weight: 900;
}

*,
html {
  scroll-behavior: smooth !important;
}
